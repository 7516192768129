@import "~@ng-select/ng-select/themes/ant.design.theme.css"
@import "src/variables"

body
  margin: 0
  font-family: Roboto, "Helvetica Neue", sans-serif

.dirty-field
  border-color: $secondary-blue
  background-color: $background-blue2

.invalid-field, .invalid-field.ng-select .ng-select-container
  border-color: $primary-red
  background-color: $background-red
  border-width: thin
.ng-select
  border: unset
.ng-select .ng-select-container
  border-width: thin
  border-color: lightgray
  box-shadow: unset
.ng-select.ng-select-opened>.ng-select-container
  box-shadow: unset
