$primary-blue: #156890
$primary-red: rgba(224, 44, 54)
$primary-green: rgb(27, 130, 27)
$primary-orange: rgb(255 175 114)

$secondary-blue: #53b8e8

$background-blue: rgba($secondary-blue, 0.05)
$background-blue2: rgba($secondary-blue, 0.05)
$background-red: rgba($primary-red, 0.05)
$background-orange: rgba($primary-orange, 0.05)
